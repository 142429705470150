<!-- 设备日志 -->
<template>
  <div class='box-card'>
    <div style="text-align: left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="设备序列号：">
          <el-select popper-class="my-select" filterable v-model="queryForm.device_face_id" clearable
            placeholder="请选择设备" @change="onSubmit()">
            <el-option v-for="item in faceDeviceList" :key="item._id" :label="item.device_face_name"
              :value="item.device_face_serial">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送状态：">
          <el-select popper-class="my-select" filterable v-model="queryForm.send_status" placeholder="请选择发送状态..."
            @change="onSubmit()">
            <el-option v-for="item in sendStatusList" :key="item.value" :label="item.sendStatus" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回复结果：">
          <el-select popper-class="my-select" filterable v-model="queryForm.receive_code" placeholder="请选择回复结果..."
            @change="onSubmit()">
            <el-option v-for="item in receiveCodeList" :key="item.value" :label="item.receiveCode" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间：">
          <el-date-picker size="small" v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
            start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="设备日志">
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
              <template slot-scope="{row}" slot="send_status">
                {{ sendStatus( row.send_status) }}
            </template>
             <template slot-scope="{row}" slot="receive_code">
              {{ receiveCode(row.receive_code) }}
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            :row-style="{ height: '50px' }">
            <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
            <el-table-column prop="send_operator" label="操作类型">
            </el-table-column>
            <el-table-column label="发送状态">
              <template slot-scope="scope">
                {{ sendStatus(scope.row.send_status) }}
              </template>
            </el-table-column>
            <el-table-column prop="send_person_name" label="人员姓名"></el-table-column>
            <el-table-column label="接收结果">
              <template slot-scope="scope">
                {{ receiveCode(scope.row.receive_code) }}
              </template>
            </el-table-column>
            <el-table-column prop="receive_fail" label="失败详情"></el-table-column>
            <el-table-column prop="create_name" label="创建人"></el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
  </div>
</template>

<script>
import { getDeviceLogList, getFaceDeviceList } from '@/api/securityApi'
import utils from '../../../../../utils/utils.js'
export default {
  name: 'deviceLog',
  components: {},
  props: {},
  data () {
    return {
      queryForm: {
        device_face_id: '', // 设备序列号
        send_status: -1, // 发送状态 0:未发送， 1：发送中， 2：已发送， -1：全部
        receive_code: -1, // 回复结果 0：未回复， 1：成功， 2：失败， -1：全部
        start_time: '',
        end_time: '',
        PageIndex: 1,
        PageSize: 10
      },
      timeduan: '',
      sendStatusList: [
        {
          sendStatus: '未发送',
          value: 0
        },
        {
          sendStatus: '发送中',
          value: 1
        },
        {
          sendStatus: '已发送',
          value: 2
        },
        {
          sendStatus: '全部',
          value: -1
        }
      ],
      receiveCodeList: [
        {
          receiveCode: '未回复',
          value: 0
        },
        {
          receiveCode: '成功',
          value: 1
        },
        {
          receiveCode: '失败',
          value: 2
        },
        {
          receiveCode: '全部',
          value: -1
        }
      ],
      faceDeviceList: [], // 门禁设备列表
      tableData: [], // 表格数据
      total: 0,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '设备名称',
            prop: 'device_face_name'
          }, {
            label: '操作类型',
            prop: 'send_operator'
          }, {
            label: '发送状态',
            prop: 'send_status',
            slot: true
          }, {
            label: '人员姓名',
            prop: 'send_person_name'
          }, {
            label: '接收结果',
            prop: 'receive_code',
            slot: true
          }, {
            label: '失败详情',
            prop: 'receive_fail'
          }, {
            label: '创建人',
            prop: 'create_name'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  computed: {},
  methods: {
    // 获取设备日志列表
    async fnGetDeviceLog () {
      const res = await getDeviceLogList(this.queryForm)
      if (res.Code === 200 && res.Data) {
        res.Data.DataList
          .forEach(element => {
            element.send_operator = utils.getOperationType(element.send_operator)
          })
      }
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetDeviceLog()
    },
    // 获取门禁设备列表
    async fnGetFaceDeviceList () {
      const res = await getFaceDeviceList()
      console.log('获取门禁设备列表----', res)
      this.faceDeviceList = res.Code === 200 && res.Data ? res.Data : []
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetDeviceLog()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetDeviceLog()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetDeviceLog()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetDeviceLog()
    // },
    // 发送状态
    sendStatus (code) {
      if (code === 0) {
        return '未发送'
      } else if (code === 1) {
        return '发送中'
      } else if (code === 2) {
        return '已发送'
      } else if (code === -1) {
        return '全部'
      }
    },
    // 接受结果
    receiveCode (code) {
      if (code === 0) {
        return '未回复'
      } else if (code === 1) {
        return '成功'
      } else if (code === 2) {
        return '失败'
      } else if (code === -1) {
        return '全部'
      }
    }
  },
  created () { },
  mounted () {
    this.fnGetDeviceLog()
    this.fnGetFaceDeviceList()
  }
}
</script>

<style scoped lang="less">
.query_form {
  margin-top: 20px;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px!important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 180px!important;
}
</style>
